<template>
  <div class="g-section">
    <div class="g-header">
      <h3>OOD体系课程，三大框架学习完整的面向对象设计</h3>
      <p>
        从对象三要素，到UML，7大设计原则，到23种设计模式全讲，到最后到OOD案例
      </p>
    </div>
    <div class="container">
      <div class="designModes hoverUp">
        <img src="@/assets/courseComponents/designMode.png" alt="" />
        <div class="title">7大设计原则</div>
        <div class="description">
          五大SOLID原则基础上扩展
          <br />
          7大设计原则以代码为模板全部精讲
        </div>
      </div>
      <div class="designModes hoverUp">
        <img src="@/assets/courseComponents/OOdesign.png" alt="" />
        <div class="title">23种设计模式</div>
        <div class="description">
          Design Pattern知识体系
          <br />
          23种模式全部精讲，以代码进行实现
        </div>
      </div>
      <div class="designModes hoverUp">
        <img src="@/assets/courseComponents/systemDesign.png" alt="" />
        <div class="title">12个OOD案例</div>
        <div class="description">
          12个高频常考OOD案例
          <br />
          可以在面试中直接写的标准答案
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CourseDetail",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 72px 0;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  color: #676767;
  font-size: 16px;
  margin-bottom: 48px;
}

.container {
  width: 1200px;
  padding-left: 120px;
  padding-right: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.designModes {
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  margin: 0 20px;
  img {
    width: 270px;
    vertical-align: top;
  }
  .title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
  }
  .description {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #666666;
  }
}
</style>