<template>
  <div class="g-section">
    <div class="g-header">
      <h3>八种写法八种写法八种写法八种写法八种写法</h3>
      <p>记叙文六要素:时间、地点、人物、事件的起因、经过、结果</p>
    </div>
    <div class="container">
      <img src="@/assets/courseComponents/eight-steps.png" alt="" />
      <div class="centerText">
        八种写法
        <br />
        逐一讲解
      </div>
      <div class="leftText step1">
        <div class="subTitle">静态常量</div>
        <div class="title">饿汉式</div>
        <div class="steps">
          01
          <br />
          STEP
        </div>
      </div>
      <div class="leftText step2">
        <div class="subTitle">静态常量</div>
        <div class="title">饿汉式</div>
        <div class="steps">
          01
          <br />
          STEP
        </div>
      </div>
      <div class="leftText step3">
        <div class="subTitle">静态常量</div>
        <div class="title">饿汉式</div>
        <div class="steps">
          01
          <br />
          STEP
        </div>
      </div>
      <div class="leftText step4">
        <div class="subTitle">静态常量</div>
        <div class="title">饿汉式</div>
        <div class="steps">
          01
          <br />
          STEP
        </div>
      </div>
      <div class="rightText step5">
        <div class="steps">
          01
          <br />
          STEP
        </div>
        <div class="title">饿汉式</div>
        <div class="subTitle">静态常量</div>
      </div>
      <div class="rightText step6">
        <div class="steps">
          01
          <br />
          STEP
        </div>
        <div class="title">饿汉式</div>
        <div class="subTitle">静态常量</div>
      </div>
      <div class="rightText step7">
        <div class="steps">
          01
          <br />
          STEP
        </div>
        <div class="title">饿汉式</div>
        <div class="subTitle">静态常量</div>
      </div>
      <div class="rightText step8">
        <div class="steps">
          01
          <br />
          STEP
        </div>
        <div class="title">饿汉式</div>
        <div class="subTitle">静态常量</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "eightWrites",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 36px 0;
  width: 100%;
  min-height: 600px;
  position: relative;
  background: url(../../../assets/courseComponents/eight-bg.png) no-repeat;
  background-size: 100% 100%;
}
.umlbg {
  vertical-align: top;
  width: 100%;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  font-size: 16px;
  margin-bottom: 30px;
  color: #fff;
}

.container {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.centerText {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.leftText,
.rightText {
  display: flex;
  position: absolute;
  .subTitle {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
    padding: 30px;
  }
  .title {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #2c5de5;
    line-height: 36px;
    padding: 30px 0;
  }
  .steps {
    font-size: 22px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    padding: 12px 30px;
  }
}
.step1 {
  top: -1%;
  left: 11.8%;
}
.step2 {
  top: 26%;
  left: 11.8%;
}
.step3 {
  top: 51%;
  left: 11.8%;
}
.step4 {
  top: 78%;
  left: 11.8%;
}

.step5 {
  top: -1%;
  right: 11.8%;
}
.step6 {
  top: 26%;
  right: 11.8%;
}
.step7 {
  top: 51%;
  right: 11.8%;
}
.step8 {
  top: 78%;
  right: 11.8%;
}
</style>